import React, { useEffect, useRef, useState, useMemo } from 'react';
import { ModalComponent, getFrom, handleNumberInputs, updateLocalStorageItems, Spinner, focusArea } from './utilities';
import { breadCrumbAction, loginStatusAction, userInfoAction, loaderAction, modalAction, cartAction, myOrdersAction, globalDataAction } from '../../../actions';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { XYZ_ID } from '../../../constants';

const Checkout = ({ breadCrumbAction, cart, isLoggedIn, userInfo, compCode, loaderAction, modalAction, cartAction, globalData, globalDataAction }) => {

  const history = useHistory();
  const prescription = true;

  useEffect(() => {
    if (!globalData.location.LocationId) {
        alert('Please select a Service Location before we proceed with your order..');
        history.goBack();
        return;
    } else if (!isLoggedIn) {
        modalAction('LOGIN_MODAL', true);
        return;
    } else {
        modalAction('LOGIN_MODAL', false);
        setLocationModalActive(true);
    }
  }, [userInfo.Pin, globalData.location.LocationId, isLoggedIn])

  useEffect(() => {
    if (compCode === XYZ_ID) return;
    let cartArray = Object.values(cart.pharmacy);                                                               
    let stockQtyList = cartArray.map(item => item.StockQty);                      
    if (stockQtyList.includes(0)) {
        alert('Please Remove or move to wishlist, out of Stock Products in your cart to continue.');
        history.push('/cartPage');
    }
  }, [])

  useEffect(() => {
    breadCrumbAction({links: [{name: 'Home', link: '/'}, {name: 'Checkout', link: '/checkout'}], activeLink: '/checkout'});
  },[breadCrumbAction])  

  const cartArray = Object.values(cart.pharmacy);                                                               // Convert cart object into list.
  const cartItemsValueList = cartArray.map(item => item.count * item.SRate);                           // Array of all item's price * quantity selected.
  const cartSubtotal = cartItemsValueList.reduce((total, num) => total + num, 0).toFixed(2);           // Reducing to get sum of cartItemsValueList.
  const [couponTab, setCouponTab] = useState(false);

  const [orderData, setOrderData] = useState({
    PartyCode: '',
    InsBy: '',              
    PaymentMethod: 'COD',
    Amount: '',
    EncCompanyId: '',
    SalesDetailsList: [],                               

    BillingState: userInfo.State,
    BillingAddress: userInfo.Address + ' ' + userInfo.Address2 + ' ' + userInfo.Pin,
    DeliveryParty: userInfo.PartyCode,
    DeliveryState: userInfo.State,
    DeliveryAddress : userInfo.Address + ' ' + userInfo.Address2 + ' ' + userInfo.Pin,
  });
  
  const [locationModalActive, setLocationModalActive] = useState(false);
  const [isDeliverable, setDeliverable] = useState(false);
  const [selectedServiceLocation, setSelectedServiceLocation] = useState({ LocationName: '', Address: '', PIN: '' });
  
  let orderList = useMemo(() => Object.values(cart.pharmacy).map(i => ({             // since we need value (array) from orderList hence using useMemo instead of useCallback which return function. both can suppress useEffect dependency warning.
      BillQty: i.count,                                                              // We can't use cartArray defined above. because it cartArray recalculated on every page render and usecallback thinks it's been changed  
      ItemId: i.ItemId,                                                              // and hence triggers recalculation / re-run of orderList function which makes below useEffect to reRender the page (again recalculates cartArray) resulting falling in a loop.
      Unit: i.Unit,
      MRP: i.ItemMRP,
      MRPOnDisPer: i.DiscountPer,
    //   Dicount: i.DiscountPer,
    //   DiscountText: (i.count * i.ItemMRP) - (i.count * i.SRate),
      Rate: (((i.count * i.SRate) - (((i.count * i.SRate * i.IGSTRATE) / (i.IGSTRATE + 100))))/i.count).toFixed(2),
      PackSizeId: i.PackSizeId ? i.PackSizeId : 0,
      Amount: i.count * i.SRate,
      TaxableAmount: ((i.count * i.SRate) - ((i.count * i.SRate * i.IGSTRATE) / (i.IGSTRATE + 100))).toFixed(2),
      CGSTRATE: i.CGSTRATE,
      SGSTRATE: i.SGSTRATE,
      IGSTRATE: i.IGSTRATE
  })),[cart])  
  
  useEffect(() => {
      async function init() {    
        if (isLoggedIn) {
            setOrderData((preValues) => ({
                ...preValues,
                PartyCode: userInfo.PartyCode,
                InsBy: userInfo.UserId,              
                PaymentMethod: 'COD',
                Amount: cartSubtotal,
                EncCompanyId: compCode,
                SalesDetailsList: orderList,                       
                BillingState: userInfo.State,
                BillingAddress: userInfo.Address + ' ' + userInfo.Address2 + ' ' + userInfo.Pin,
                DeliveryParty: userInfo.PartyCode,
                DeliveryState: userInfo.State,
                DeliveryAddress : userInfo.Address + ' ' + userInfo.Address2 + ' ' + userInfo.Pin,
                LocationId: globalData.location.LocationId
            }))
        } else {
            setOrderData((preValues) => ({
                ...preValues,
                PartyCode: '0',
                InsBy: '0',
                BillingState: '',
                BillingAddress: '',
                DeliveryParty: '',
                DeliveryState: '',
                DeliveryAddress : '',              
            }))
        }
    }
    init();
  },[isLoggedIn, userInfo, cartSubtotal, compCode, orderList, globalData.location.LocationId])

  const dummyFunction = () => false;

  const placeOrder = async () => {
    console.log(orderData);
    if (!isLoggedIn) return alert('please login to place an order.');
    if (!orderData.LocationId) return alert('Please select a Service Location before making an order.');
    if (!orderData.SalesDetailsList.length) return alert('Add something to your cart before making an order');
    try {
        loaderAction(true);
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/Pharma`, orderData);
        loaderAction(false);
        console.log(res.data); 
        cartAction('DUMP_CART', {}, 'pharmacy');
        updateLocalStorageItems();
        modalAction('ORDER_SUCCESS_MODAL', true);
    } catch (err) {
        console.log(err);
        return false;
    }
  }

  const closeModal = () => {
    setLocationModalActive(false);
  }

  useEffect(() => {
    if (!globalData.location.LocationId) return;
    setSelectedServiceLocation(globalData.location);
  }, [globalData.location])

  return (                                                                                                      
    <>
        <div className="checkout-area pt-2 pt-lg-3 pb-30">
            <div className="container">
                <div className="row mt-0 mt-lg-3">
                    <div className="col-lg-5 col-12">
                        {!isLoggedIn && <h3>Please <span className='text-decoration-underline text-primary' role='button' onClick={() => modalAction('LOGIN_MODAL', true)}>Login</span> to place an order.</h3>}
                        {isLoggedIn && <div>
                            <div className='shipping-details p-4 px-lg-0'>
                                <h3 className='mb-4'>Shipping Details</h3>
                                <div className="your-order-table user-details table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="cart-product-name">Deliver To : &nbsp;&nbsp;&nbsp;</th>
                                                <th className="cart-product-total" style={{width: '73%'}}>{userInfo.Name}</th> 
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="cart_item">
                                                <td className="cart-product-name"> Phone <strong className="product-quantity"> : </strong></td>
                                                <td className="cart-product-total"><span className="amount">{userInfo.RegMob1}</span></td>
                                            </tr>
                                            <tr className="cart_item">
                                                <td className="cart-product-name"> Address <strong className="product-quantity"> : </strong></td>
                                                <td className="cart-product-total"><span className="amount">{userInfo.Address2}, {userInfo.Address}, {userInfo.City}, {userInfo.StateName}</span></td>
                                            </tr>
                                            <tr className="cart_item">
                                                <td className="cart-product-name"> Pin Code <strong className="product-quantity"> : </strong></td>
                                                <td className="cart-product-total">
                                                    <span className="amount">{userInfo.Pin}</span>
                                                    {/* {!isDeliverable && <p className='text-danger mb-0' role='button'>We do not deliver to this address. <span className='text-primary text-decoration-underline' onClick={() => setLocationModalActive(true)}>Change Delivery Address</span></p>} */}
                                                </td>
                                            </tr>
                                            <tr className="cart_item">
                                                <td className="cart-product-name"> E-mail <strong className="product-quantity"> : </strong></td>
                                                <td className="cart-product-total"><span className="amount">{userInfo.Email}</span></td>
                                            </tr>
                                            <tr className="cart_item">
                                                <td className="cart-product-total" colSpan={2}>
                                                    <Link to='#' onClick={() => {modalAction('EDIT_USER_MODAL', true)}} className='add_an_item_btn ms-auto'>EDIT</Link>
                                                </td>                          
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* {!selectedServiceLocation.PIN && <h3 className='d-flex justify-content-between align-items-center mt-4'>Service Location <span className='add_an_item_btn m-0' role='button' onClick={() => setLocationModalActive(true)}>SELECT</span></h3>} */}
                            </div>
                            {selectedServiceLocation.PIN && <div className='row'>
                                <div className="col-md-12">
                                    <h6 className='my-4'>Selected Service Location</h6>
                                    <div className="card-1 location-card" style={{fontSize: '1.2em'}}>
                                        <div style={{display: 'flex', gap: '1em'}}>
                                            <i className='bx bxs-shopping-bag' style={{fontSize: '3.5em', color: 'var(--bg-2)'}}></i>
                                            <div>
                                                <h5 style={{color: 'var(--bg-2)'}}>{selectedServiceLocation.LocationName}</h5>
                                                <h6>{selectedServiceLocation.Address}</h6>
                                                <p>{selectedServiceLocation.PIN}</p>
                                            </div>
                                        </div>
                                        {/* <i className='bx bx-check-circle' style={{fontSize: '2em', color: 'var(--bg-1)'}}></i> */}
                                        {/* <button className="controlled-btn ms-auto" type="button" onClick={() => setLocationModalActive(true)}>CHANGE</button>    */}
                                    </div>
                                </div>
                            </div>}
                        </div>}
                    </div>
                    <div className="col-lg-7 col-12">
                        {isLoggedIn && <div className="your-order">
                            <h3>Your order</h3>
                            <div className="your-order-table table-responsive" style={{borderBottom: '3px solid #00ddd4', marginBottom: '2.5rem'}}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="cart-product-name">Product</th>
                                            <th className="cart-product-name text-end">MRP</th>
                                            <th className="cart-product-name text-end">Discount</th>
                                            <th className="cart-product-total text-end">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartArray.map((item) => (
                                            <tr key={item.ItemId} className="cart_item">
                                                <td className="cart-product-name"> {item.Description}<strong className="product-quantity"> × {item.count}</strong></td>
                                                <td className="cart-product-name text-end"><strong className="product-quantity">₹{item.ItemMRP}</strong></td>
                                                <td className="cart-product-name text-end"><strong className="product-quantity"> {item.DiscountPer}%</strong></td>
                                                <td className="cart-product-total text-end text-nowrap">₹{item.SRate}<span className="amount"> × {item.count}</span></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr className="cart-subtotal">
                                            <th>Cart Subtotal</th>
                                            <td></td>
                                            <td></td>
                                            <th className='text-end'><span className="amount">₹ {cartSubtotal}</span></th>
                                        </tr>
                                        <tr className="cart-subtotal">
                                            <th>Service Charge</th>
                                            <td></td>
                                            <td></td>
                                            <th className='text-end'><span className="amount">₹ 00</span></th>
                                        </tr>
                                        <tr className="order-total">
                                            <th>Order Total</th>
                                            <td></td>
                                            <td></td>
                                            <th className='text-end'><strong><span className="amount">₹ {cartSubtotal}</span></strong></th>
                                        </tr>
                                    </tfoot>
                                </table>
                                <div className='d-flex justify-content-between align-items-center' style={{borderTop: '1px solid #d3d3d3', padding: '1em 0 0.1em'}}>
                                    <h6>Payment Method</h6>
                                    <h6><i className='bx bxs-badge-check' style={{fontSize: '3rem', verticalAlign: 'middle', color: '#00BCD4', marginBottom: '0.4rem'}}></i> Cash on Delivery</h6>
                                </div>
                                {/* <div className='d-flex justify-content-between align-items-center' style={{borderTop: '1px solid #d3d3d3', padding: '1em 0 0.1em'}}>
                                    <h6 className='text-danger'>ADD PRESCRIPTION</h6>
                                    <h6 onClick={() => modalAction('PRESCRIPTION_MODAL', true)} style={{color: '#007bff', cursor: 'pointer'}}><i className='bx bx-file' style={{fontSize: '3rem', verticalAlign: 'middle', marginBottom: '0.4rem'}}></i> UPLOAD</h6>
                                </div> */}
                            </div>
                            {/* <div className='coupon-accordion'>
                                <h3 onClick={() => setCouponTab(!couponTab)}>Have a coupon? <span id="showcoupon">Click here to enter your code</span></h3>
                                {couponTab && <div id="checkout_coupon" className="coupon-checkout-content">
                                    <div className="coupon-info">
                                        <form action="#">
                                            <p className="checkout-coupon">
                                                <input placeholder="Coupon code" type="text" onChange={dummyFunction}/>
                                                <input value="Apply Coupon" type="submit" onChange={dummyFunction}/>
                                            </p>
                                        </form>
                                    </div>
                                </div>}
                            </div> */}
                            <div className="payment-method">
                                {/* <h4>Payment Method</h4> */}
                                <div className="payment-accordion">
                                    {/* <div id="accordion">
                                        <div className="card shadow-none">
                                            <div className="card-header" id="#payment-3">
                                                <h5 className="panel-title">
                                                    <Link to='#' className="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        <i className='bx bxs-badge-check' style={{fontSize: '3rem', verticalAlign: 'middle', color: '#00BCD4', marginBottom: '0.4rem'}}></i> Cash on Delivery
                                                    </Link>
                                                </h5>
                                            </div>
                                            <div id="collapseThree" className="collapse" data-parent="#accordion">
                                                <div className="card-body">
                                                    <p>Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order won’t be shipped until the funds have cleared in our account.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="order-button-payment">
                                        {/* <p className='text-dark mb-2' style={{fontFamily: 'Lato', fontWeight: 600}}><i class='bx bxs-info-square me-2 text-danger' style={{fontSize: '1.5em', verticalAlign: 'sub'}}></i>Please Attach your prescription to place an order.</p> */}
                                        <button type='submit' className={`ms-auto ${(isLoggedIn && isDeliverable && prescription) ? '' : 'pe-none opacity-50 bg-secondary'}`} onClick={placeOrder}>Place Order</button>
                                    </div>
                                    {isDeliverable ? '' : <div className='row mt-4'>    
                                        <div className="col-md-12">
                                            <div className="checkout-form-list position-relative">
                                                <p className='text-danger mb-0 mt-2'>Now we have no service at your PIN code - {userInfo.Pin} We will be available in your area very soon.</p>
                                            </div>
                                        </div>
                                        <div className="cta-no-location your-order-table user-details table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="cart-product-name"><i className='bx bxs-info-circle' style={{fontSize: '1.85rem', color: 'orange'}}></i> What you can do !</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="cart_item">
                                                        <td className="cart-product-name">Change your address <button onClick={() => {closeModal();modalAction('EDIT_USER_MODAL', true);}} className="controlled-btn ms-auto" type="button" style={{fontSize: '1.3rem'}}>Change Address</button></td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="cart-product-name">Change the area <button onClick={() => {closeModal();focusArea(globalDataAction);}} className="controlled-btn ms-auto" type="button" style={{fontSize: '1.3rem'}}>Change Area</button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        <ModalComponent 
            isActive={locationModalActive} 
            className={'location-selection-modal'} 
            child={<LocationModal closeModal={closeModal} userInfo={userInfo}  compCode={compCode} globalData={globalData} globalDataAction={globalDataAction} modalAction={modalAction} setDeliverable={setDeliverable} />}
        />
    </>
  )
}

const mapStateToCheckout = (state) => {
  return { compCode: state.compCode, cart: state.cart, isLoggedIn: state.isLoggedIn, userInfo: state.userInfo, modals: state.modals, globalData: state.globalData };
}

export default connect(mapStateToCheckout, {breadCrumbAction, loginStatusAction, userInfoAction, loaderAction, modalAction, cartAction, myOrdersAction, globalDataAction})(Checkout);


function LocationModal({ closeModal, compCode, userInfo, globalData, setDeliverable, globalDataAction, modalAction }) {

    const [location, setLocation] = useState({ Pin: '' });
    const [locationList, setLocationList] = useState({loading: true, data: '', err: {status: false, msg: ''}});
    
    useEffect(() => {
        if (location.Pin.length < 6) return; 
        if (!globalData.location.LocationId) return; 
        const getServiceLocations = async () => {
            const res = await getFrom(`https://myapps.gsterpsoft.com/api/Location/Get?CID=${compCode}&LocationId=${globalData.location.LocationId}&PinCode=${location.Pin}`, {}, setLocationList);            // using useCallback to avoid esling warning about useEffect dependencies.
            if (res) {
                setLocationList(res);   
            }
        }
        setTimeout(() => {
            getServiceLocations();
        }, 1000);
    }, [location.Pin, globalData.location.LocationId])

    useEffect(() => {
        setLocation({ Pin: userInfo.Pin })
    }, [])

    const renderLocationList = (data) => {
        if (data.loading) {
          return <Spinner min_height='19rem' fSize='1.5rem'/>;
        } else if (data.err.status) {
          return <div className='text-center my-5'><h2 className="text-danger mark">An error occured, please try again later. Error code: <span className='text-dark'>{data.err.msg}</span></h2></div>;
        } else if (data.data === 0) {
          setDeliverable(false);
          return <p className='text-danger mb-0 mt-2'>Now we have no service in this PIN - We will be available in your area very soon.</p>;
        } else {
          closeModal();
          setDeliverable(true);
          return;
        }
    }    

    return (
        <form>
            <div className='card'>
                <h5 className="card-header d-flex justify-content-between" style={{padding: '0.7em 1em'}}>Please Select a Service Location <i className='bx bx-x-circle' onClick={closeModal} role='button' style={{fontSize: '1.2em'}}></i></h5>
                <div className='card-body' style={{padding: '1.3em'}}>
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="checkout-form-list position-relative">
                                <label>Pincode / Zip <span className="required">*</span></label>
                                <input readOnly type="text" name='Pin' value={location.Pin} onChange={(e) => {handleNumberInputs(e, setLocation);}} autoComplete='off' maxLength='6'/>
                                {renderLocationList(locationList)}
                            </div>
                        </div>
                        <div className="cta-no-location your-order-table user-details table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="cart-product-name"><i className='bx bxs-info-circle' style={{fontSize: '1.85rem', color: 'orange'}}></i> What you can do !</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="cart_item">
                                        <td className="cart-product-name">Change your address <button onClick={() => {closeModal();modalAction('EDIT_USER_MODAL', true);}} className="controlled-btn ms-auto" type="button" style={{fontSize: '1.3rem'}}>Change Address</button></td>
                                    </tr>
                                    <tr className="cart_item">
                                        <td className="cart-product-name">Change the area <button onClick={() => {closeModal();focusArea(globalDataAction);}} className="controlled-btn ms-auto" type="button" style={{fontSize: '1.3rem'}}>Change Area</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}


// import React, { useEffect, useRef, useState, useMemo } from 'react';
// import { ModalComponent, getFrom, handleNumberInputs, updateLocalStorageItems, Spinner, focusArea } from './utilities';
// import { breadCrumbAction, loginStatusAction, userInfoAction, loaderAction, modalAction, dumpCartAction, myOrdersAction, globalDataAction } from '../../../actions';
// import { connect } from 'react-redux';
// import { Link, useHistory } from 'react-router-dom';
// import axios from 'axios';

// const Checkout = ({ breadCrumbAction, cart, isLoggedIn, userInfo, compCode, loaderAction, modalAction, dumpCartAction, globalData, globalDataAction }) => {

//   const history = useHistory();
  
//   useEffect(() => {
//     if (!globalData.location.LocationId) {
//         alert('Please select a Service Location before we proceed with your order..');
//         history.push('/cartPage');
//     };
//   }, [globalData.location.LocationId])

//   useEffect(() => {
//     let cartArray = Object.values(cart.pharmacy);                                                               
//     let stockQtyList = cartArray.map(item => item.StockQty);                      
//     if (stockQtyList.includes(0)) {
//         alert('Please Remove or move to wishlist, out of Stock Products in your cart to continue.');
//         history.push('/cartPage');
//     }
//   }, [])

//   useEffect(() => {
//     breadCrumbAction({links: [{name: 'Home', link: '/'}, {name: 'Checkout', link: '/checkout'}], activeLink: '/checkout'});
//   },[breadCrumbAction]) 
  
//   useEffect(() => {
//     if (!isLoggedIn) {
//         modalAction('LOGIN_MODAL', true);
//         return;
//     } 
//     modalAction('LOGIN_MODAL', false);
//   }, [isLoggedIn])

//   useEffect(() => {
//     if (!isLoggedIn) return;
//     setLocationModalActive(true);
//   }, [userInfo.Pin, globalData.location.LocationId, isLoggedIn])   

//   const cartArray = Object.values(cart.pharmacy);                                                               // Convert cart object into list.
//   const cartItemsValueList = cartArray.map(item => item.count * item.SRate);                           // Array of all item's price * quantity selected.
//   const cartSubtotal = cartItemsValueList.reduce((total, num) => total + num, 0).toFixed(2);           // Reducing to get sum of cartItemsValueList.
//   const [couponTab, setCouponTab] = useState(false);

//   const [orderData, setOrderData] = useState({
//     PartyCode: '',
//     InsBy: '',              
//     PaymentMethod: 'COD',
//     Amount: '',
//     EncCompanyId: '',
//     SalesDetailsList: [],                               

//     BillingState: userInfo.State,
//     BillingAddress: userInfo.Address + ' ' + userInfo.Address2 + ' ' + userInfo.Pin,
//     DeliveryParty: userInfo.PartyCode,
//     DeliveryState: userInfo.State,
//     DeliveryAddress : userInfo.Address + ' ' + userInfo.Address2 + ' ' + userInfo.Pin,
//   });
  
//   const [locationModalActive, setLocationModalActive] = useState(false);
//   const [isDeliverable, setDeliverable] = useState(false);
//   const [selectedServiceLocation, setSelectedServiceLocation] = useState({ LocationName: '', Address: '', PIN: '' });
  
//   let orderList = useMemo(() => Object.values(cart.pharmacy).map(i => ({             // since we need value (array) from orderList hence using useMemo instead of useCallback which return function. both can suppress useEffect dependency warning.
//       BillQty: i.count,                                                     // We can't use cartArray defined above. because it cartArray recalculated on every page render and usecallback thinks it's been changed  
//       ItemId: i.ItemId,                                                     // and hence triggers recalculation / re-run of orderList function which makes below useEffect to reRender the page (again recalculates cartArray) resulting falling in a loop.
//       Unit: i.Unit,
//       MRP: i.ItemMRP,
//       MRPOnDisPer: i.DiscountPer,
//       Rate: (((i.count * i.SRate) - (((i.count * i.SRate * i.IGSTRATE) / (i.IGSTRATE + 100))))/i.count).toFixed(2),
//       PackSizeId: i.PackSizeId ? i.PackSizeId : 0,
//       Amount: i.count * i.SRate,
//       TaxableAmount: ((i.count * i.SRate) - ((i.count * i.SRate * i.IGSTRATE) / (i.IGSTRATE + 100))).toFixed(2),
//       CGSTRATE: i.CGSTRATE,
//       SGSTRATE: i.SGSTRATE,
//       IGSTRATE: i.IGSTRATE
//   })),[cart])  
  
//   useEffect(() => {
//       async function init() {    
//         if (isLoggedIn) {
//             setOrderData((preValues) => ({
//                 ...preValues,
//                 PartyCode: userInfo.PartyCode,
//                 InsBy: userInfo.UserId,              
//                 PaymentMethod: 'COD',
//                 Amount: cartSubtotal,
//                 EncCompanyId: compCode,
//                 SalesDetailsList: orderList,                       
//                 BillingState: userInfo.State,
//                 BillingAddress: userInfo.Address + ' ' + userInfo.Address2 + ' ' + userInfo.Pin,
//                 DeliveryParty: userInfo.PartyCode,
//                 DeliveryState: userInfo.State,
//                 DeliveryAddress : userInfo.Address + ' ' + userInfo.Address2 + ' ' + userInfo.Pin,
//                 LocationId: globalData.location.LocationId
//             }))
//         } else {
//             setOrderData((preValues) => ({
//                 ...preValues,
//                 PartyCode: '0',
//                 InsBy: '0',
//                 BillingState: '',
//                 BillingAddress: '',
//                 DeliveryParty: '',
//                 DeliveryState: '',
//                 DeliveryAddress : '',              
//             }))
//         }
//     }
//     init();
//   },[isLoggedIn, userInfo, cartSubtotal, compCode, orderList, globalData.location.LocationId])

//   const dummyFunction = () => false;

//   const placeOrder = async () => {
//     console.log(orderData);
//     if (!isLoggedIn) return alert('please login to place an order.');
//     if (!orderData.LocationId) return alert('Please select a Service Location before making an order.');
//     if (!orderData.SalesDetailsList.length) return alert('Add something to your cart before making an order');
//     try {
//         loaderAction(true);
//         const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/Pharma`, orderData);
//         loaderAction(false);
//         console.log(res.data); 
//         dumpCartAction();
//         updateLocalStorageItems();
//         modalAction('ORDER_SUCCESS_MODAL', true);
//     } catch (err) {
//         console.log(err);
//         return false;
//     }
//   }

//   const closeModal = () => {
//     setLocationModalActive(false);
//   }

//   useEffect(() => {
//     if (!globalData.location.LocationId) return;
//     setSelectedServiceLocation(globalData.location);
//   }, [globalData.location])

//   return (                                                                                                      
//     <>
//         <div className="checkout-area pt-2 pt-lg-3 pb-30">
//             <div className="container">
                
//                 <div className="row mt-0 mt-lg-3">
//                     <div className="col-lg-6 col-12">

//                         {!isLoggedIn && <h3>Please <span className='text-decoration-underline text-primary' role='button' onClick={() => modalAction('LOGIN_MODAL', true)}>Login</span> to place order.</h3>}

//                         {isLoggedIn && <div>
//                             <div className='shipping-details p-4 px-lg-0'>
//                                 <h3 className='mb-4'>Shipping Details</h3>
//                                 <div className="your-order-table user-details table-responsive">
//                                     <table className="table">
//                                         <thead>
//                                             <tr>
//                                                 <th className="cart-product-name">Deliver To : &nbsp;&nbsp;&nbsp;</th>
//                                                 <th className="cart-product-total" style={{width: '73%'}}>{userInfo.Name}</th> 
//                                             </tr>
//                                         </thead>
//                                         <tbody>
//                                             <tr className="cart_item">
//                                                 <td className="cart-product-name"> Phone <strong className="product-quantity"> : </strong></td>
//                                                 <td className="cart-product-total"><span className="amount">{userInfo.RegMob1}</span></td>
//                                             </tr>
//                                             <tr className="cart_item">
//                                                 <td className="cart-product-name"> Address <strong className="product-quantity"> : </strong></td>
//                                                 <td className="cart-product-total"><span className="amount">{userInfo.Address2}, {userInfo.Address}</span></td>
//                                             </tr>
//                                             <tr className="cart_item">
//                                                 <td className="cart-product-name"> Pin Code <strong className="product-quantity"> : </strong></td>
//                                                 <td className="cart-product-total">
//                                                     <span className="amount">{userInfo.Pin}</span>
//                                                     {/* {!isDeliverable && <p className='text-danger mb-0' role='button'>We do not deliver to this address. <span className='text-primary text-decoration-underline' onClick={() => setLocationModalActive(true)}>Change Delivery Address</span></p>} */}
//                                                 </td>
//                                             </tr>
//                                             <tr className="cart_item">
//                                                 <td className="cart-product-name"> E-mail <strong className="product-quantity"> : </strong></td>
//                                                 <td className="cart-product-total"><span className="amount">{userInfo.Email}</span></td>
//                                             </tr>
//                                             <tr className="cart_item">
//                                                 <td className="cart-product-total" colSpan={2}>
//                                                     <Link to='#' onClick={() => {modalAction('EDIT_USER_MODAL', true)}} className='add_an_item_btn ms-auto'>EDIT</Link>
//                                                 </td>                          
//                                             </tr>
//                                         </tbody>
//                                     </table>
//                                 </div>
//                                 {/* {!selectedServiceLocation.PIN && <h3 className='d-flex justify-content-between align-items-center mt-4'>Service Location <span className='add_an_item_btn m-0' role='button' onClick={() => setLocationModalActive(true)}>SELECT</span></h3>} */}
//                                 {selectedServiceLocation.PIN && <div className='row mt-3'>
//                                     <div className="col-md-12">
//                                         <h6>Selected Service Location</h6>
//                                         <div className="card-1 location-card" style={{fontSize: '1.2em'}}>
//                                             <div style={{display: 'flex', gap: '1em'}}>
//                                                 <i className='bx bxs-shopping-bag' style={{fontSize: '3.5em', color: 'var(--bg-2)'}}></i>
//                                                 <div>
//                                                     <h5 style={{color: 'var(--bg-2)'}}>{selectedServiceLocation.LocationName}</h5>
//                                                     <h6>{selectedServiceLocation.Address}</h6>
//                                                     <p>{selectedServiceLocation.PIN}</p>
//                                                 </div>
//                                             </div>
//                                             {/* <i className='bx bx-check-circle' style={{fontSize: '2em', color: 'var(--bg-1)'}}></i> */}
//                                             {/* <button className="controlled-btn ms-auto" type="button" onClick={() => setLocationModalActive(true)}>CHANGE</button>    */}
//                                         </div>
//                                     </div>
//                                 </div>}
//                             </div>
//                         </div>}
//                     </div>
//                     <div className="col-lg-6 col-12">
//                         {isLoggedIn && <div className="your-order">
//                             <h3>Your order</h3>
//                             <div className="your-order-table table-responsive">
//                                 <table className="table">
//                                     <thead>
//                                         <tr>
//                                             <th className="cart-product-name">Product</th>
//                                             <th className="cart-product-name text-end">MRP</th>
//                                             <th className="cart-product-name text-end">Discount</th>
//                                             <th className="cart-product-total text-end">Total</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {cartArray.map((item) => (
//                                             <tr key={item.ItemMRP} className="cart_item">
//                                                 <td className="cart-product-name"> {item.Description}<strong className="product-quantity"> × {item.count}</strong></td>
//                                                 <td className="cart-product-name text-end"><strong className="product-quantity">₹{item.ItemMRP}</strong></td>
//                                                 <td className="cart-product-name text-end"><strong className="product-quantity"> {item.DiscountPer}%</strong></td>
//                                                 <td className="cart-product-total text-end">₹{item.SRate}<span className="amount"> × {item.count}</span></td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                     <tfoot>
//                                         <tr className="cart-subtotal">
//                                             <th>Cart Subtotal</th>
//                                             <td></td>
//                                             <td></td>
//                                             <th className='text-end'><span className="amount">₹ {cartSubtotal}</span></th>
//                                         </tr>
//                                         <tr className="cart-subtotal">
//                                             <th>Service Charge</th>
//                                             <td></td>
//                                             <td></td>
//                                             <th className='text-end'><span className="amount">₹ 00</span></th>
//                                         </tr>
//                                         <tr className="order-total">
//                                             <th>Order Total</th>
//                                             <td></td>
//                                             <td></td>
//                                             <th className='text-end'><strong><span className="amount">₹ {cartSubtotal}</span></strong></th>
//                                         </tr>
//                                     </tfoot>
//                                 </table>
//                             </div>
//                             <div className='coupon-accordion'>
//                                 <h3 onClick={() => setCouponTab(!couponTab)}>Have a coupon? <span id="showcoupon">Click here to enter your code</span></h3>
//                                 {couponTab && <div id="checkout_coupon" className="coupon-checkout-content">
//                                     <div className="coupon-info">
//                                         <form action="#">
//                                             <p className="checkout-coupon">
//                                                 <input placeholder="Coupon code" type="text" onChange={dummyFunction}/>
//                                                 <input value="Apply Coupon" type="submit" onChange={dummyFunction}/>
//                                             </p>
//                                         </form>
//                                     </div>
//                                 </div>}
//                             </div>
//                             <div className="payment-method">
//                                 <h3>Payment Method</h3>
//                                 <div className="payment-accordion">
//                                     <div id="accordion">
//                                         <div className="card">
//                                             <div className="card-header" id="#payment-3">
//                                                 <h5 className="panel-title">
//                                                     <Link to='#' className="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
//                                                         <i className
//                                                         ='bx bxs-badge-check' style={{fontSize: '3rem', verticalAlign: 'middle', color: '#00BCD4', marginBottom: '0.4rem'}}></i> Cash on Delivery
//                                                     </Link>
//                                                 </h5>
//                                             </div>
//                                             <div id="collapseThree" className="collapse" data-parent="#accordion">
//                                                 <div className="card-body">
//                                                     <p>Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order won’t be shipped until the funds have cleared in our account.</p>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="order-button-payment">
//                                         <button type='submit' className={(isLoggedIn && isDeliverable) ? '' : 'pe-none opacity-50 bg-secondary'} onClick={placeOrder}>Place Order</button>
//                                     </div>
//                                     {isDeliverable ? '' : <div className='row mt-4'>
//                                         <div className="col-md-12">
//                                             <div className="checkout-form-list position-relative">
//                                                 <p className='text-danger mb-0 mt-2'>Now we have no service at your PIN code - {userInfo.Pin} We will be available in your area very soon.</p>
//                                             </div>
//                                         </div>
//                                         <div className="cta-no-location your-order-table user-details table-responsive">
//                                             <table className="table">
//                                                 <thead>
//                                                     <tr>
//                                                         <th className="cart-product-name"><i className='bx bxs-info-circle' style={{fontSize: '1.85rem', color: 'orange'}}></i> What you can do !</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     <tr className="cart_item">
//                                                         <td className="cart-product-name">Change your address <button onClick={() => {closeModal();modalAction('EDIT_USER_MODAL', true);}} className="controlled-btn ms-auto" type="button" style={{fontSize: '1.3rem'}}>Change Address</button></td>
//                                                     </tr>
//                                                     <tr className="cart_item">
//                                                         <td className="cart-product-name">Change the area <button onClick={() => {closeModal();focusArea(globalDataAction);}} className="controlled-btn ms-auto" type="button" style={{fontSize: '1.3rem'}}>Change Area</button></td>
//                                                     </tr>
//                                                 </tbody>
//                                             </table>
//                                         </div>
//                                     </div>}
//                                 </div>
//                             </div>
//                         </div>}
//                     </div>
//                 </div>
//             </div>
//         </div>
//         <ModalComponent 
//             isActive={locationModalActive} 
//             className={'location-selection-modal'} 
//             child={<LocationModal closeModal={closeModal} userInfo={userInfo}  compCode={compCode} globalData={globalData} globalDataAction={globalDataAction} modalAction={modalAction} setDeliverable={setDeliverable} />}
//         />
//     </>
//   )
// }

// const mapStateToCheckout = (state) => {
//   return { compCode: state.compCode, cart: state.cart, isLoggedIn: state.isLoggedIn, userInfo: state.userInfo, modals: state.modals, globalData: state.globalData };
// }

// export default connect(mapStateToCheckout, {breadCrumbAction, loginStatusAction, userInfoAction, loaderAction, modalAction, dumpCartAction, myOrdersAction, globalDataAction})(Checkout);


// function LocationModal({ closeModal, compCode, userInfo, globalData, setDeliverable, globalDataAction, modalAction }) {

//     const [location, setLocation] = useState({ Pin: '' });
//     const [locationList, setLocationList] = useState({loading: true, data: '', err: {status: false, msg: ''}});
    
//     useEffect(() => {
//         if (location.Pin.length < 6) return; 
//         if (!globalData.location.LocationId) return; 
//         const getServiceLocations = async () => {
//             const res = await getFrom(`https://myapps.gsterpsoft.com/api/Location/Get?CID=${compCode}&LocationId=${globalData.location.LocationId}&PinCode=${location.Pin}`, {}, setLocationList);            // using useCallback to avoid esling warning about useEffect dependencies.
//             if (res) {
//                 setLocationList(res);   
//             }
//         }
//         getServiceLocations();
//     }, [location.Pin, globalData.location.LocationId])

//     useEffect(() => {
//         setLocation({ Pin: userInfo.Pin })
//     }, [])

//     const renderLocationList = (data) => {
//         if (data.loading) {
//           return <Spinner min_height='19rem' fSize='1.5rem'/>;
//         } else if (data.err.status) {
//           return <div className='text-center my-5'><h2 className="text-danger mark">An error occured, please try again later. Error code: <span className='text-dark'>{data.err.msg}</span></h2></div>;
//         } else if (data.data === 0) {
//           setDeliverable(false);
//           return <p className='text-danger mb-0 mt-2'>Now we have no service in this PIN - We will be available in your area very soon.</p>;
//         } else {
//           closeModal();
//           setDeliverable(true);
//         }
//     }    

//     return (
//         <form>
//             <div className='card'>
//                 <h5 className="card-header d-flex justify-content-between" style={{padding: '0.7em 1em'}}>Please Select a Service Location <i className='bx bx-x-circle' onClick={closeModal} role='button' style={{fontSize: '1.2em'}}></i></h5>
//                 <div className='card-body' style={{padding: '1.3em'}}>
//                     <div className='row'>
//                         <div className="col-md-12">
//                             <div className="checkout-form-list position-relative">
//                                 <label>Pincode / Zip <span className="required">*</span></label>
//                                 <input readOnly type="text" name='Pin' value={location.Pin} onChange={(e) => {handleNumberInputs(e, setLocation);}} autoComplete='off' maxLength='6'/>
//                                 {renderLocationList(locationList)}
//                             </div>
//                         </div>
//                         <div className="cta-no-location your-order-table user-details table-responsive">
//                             <table className="table">
//                                 <thead>
//                                     <tr>
//                                         <th className="cart-product-name"><i className='bx bxs-info-circle' style={{fontSize: '1.85rem', color: 'orange'}}></i> What you can do !</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     <tr className="cart_item">
//                                         <td className="cart-product-name">Change your address <button onClick={() => {closeModal();modalAction('EDIT_USER_MODAL', true);}} className="controlled-btn ms-auto" type="button" style={{fontSize: '1.3rem'}}>Change Address</button></td>
//                                     </tr>
//                                     <tr className="cart_item">
//                                         <td className="cart-product-name">Change the area <button onClick={() => {closeModal();focusArea(globalDataAction);}} className="controlled-btn ms-auto" type="button" style={{fontSize: '1.3rem'}}>Change Area</button></td>
//                                     </tr>
//                                 </tbody>
//                             </table>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </form>
//     )
// }